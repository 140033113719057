import { Link } from "react-router-dom";
import { AiOutlineProfile } from "react-icons/ai";
import { GetAccount, GetServiceProvider, GetAccounts, UpdateAccount, getInfo, GetBeneficiary, CreateAccount, GetTemplatesByServiceProviderID } from "../utilities/calls";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "./CopyToClipboard";
import CreateBeneficiarySP from "./CreateBeneficiarySP";
import { BsFillTrash3Fill } from "react-icons/bs";
import DeleteModal from "./DeleteModal";
import { displayLogoURL } from "../utilities/functions";
import { CgTemplate } from "react-icons/cg";



export default function ServiceProviderList() {

    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList ] = useState([]);
    const [beneficiaryList, setBeneficiaryList ] = useState([]);
    const[currId, setCurrId] = useState();
    const[isSP, setIsSP] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const[confirmationText, setConfirmationText] = useState();
    const [spTemplates, setSpTemplates] = useState({});
    const [account, setAccount] = useState({});
    const [accountSPMap, setAccountSPMap] = useState({});
    const [accountBenMap, setAccountBenMap] = useState({});
    const [showCreateBeneSP, setShowCreateBeneSP] = useState();
    const addServiceProvider = () => {
        setShowCreateBeneSP(true);
    }

    const showFn = (state) => {
      setShowCreateBeneSP(state);
    }
    const handleDeleteClick = (id) => {
      console.log(id)
      let isSP = serviceProviderList.findIndex((sp) => (sp.id === id)) !== -1;
      if(isSP) 
        setConfirmationText("Are you certain you wish to delete this service provider?");
      else 
        setConfirmationText("Are you certain you wish to delete this beneficiary?");
      setShowDeleteDlg(true);
      setCurrId(id);
      setIsSP(isSP);
    }

    const deleteSPBen = () => {
      setShowDeleteDlg(false);

      const individualid = getInfo("IndividudalID");
      console.log(accountBenMap, accountSPMap)
      let account = isSP ? accountSPMap[currId] : accountBenMap[currId]
      let serviceProviders = account.ServiceProviders || {};
      let beneficiary = account.Beneficiaries || {};
      if(isSP) {
        delete account.ServiceProviders[currId]
      } else {
        delete account.Beneficiaries[currId]
      }
      UpdateAccount(account.id, individualid, {}, serviceProviders, beneficiary).then((response) => {
        setShowAlert(true);
      setAlertText("Deleted successfully" );
      setServiceProviderList([]);
      setBeneficiaryList([]);
      updateList();
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      }).catch((error) => { console.log("Error in delete", error);
      setShowAlert(true);
      setAlertText("Error deleting an address.");
     })   
    }

    useEffect(() => {
      serviceProviderList.forEach((sp) => {
        if(!spTemplates[sp.id]) {
          GetTemplatesByServiceProviderID(sp.id).then((resp) => {
            setSpTemplates((spTemplates) => ({...spTemplates, [sp.id] : resp.length }))
          })
        }
      });
    }, [serviceProviderList])


    const handleTemplateClick = (id) => {

    }

    const updateList = () => {
      GetAccounts().then((response) => {
        console.log('GETAccounts response', response)
        if(Object.keys(response).length === 0 ){
         CreateAccount("", {}, {}, {}).then((response) => { 
            setAccount(response);
          }).catch((err) => { console.log(err);})
        } else {
        Object.keys(response).forEach((key, index) => {
            GetAccount(key).then((accDetails) => { setAccount(accDetails); 
              Object.keys(accDetails.ServiceProviders).forEach((k, i)=> {
                GetServiceProvider(k).then((spDetails) => {(
                  setServiceProviderList((serviceProviderList)=>[...serviceProviderList, spDetails])); 
                  setAccountSPMap((accountSPMap)=> ({...accountSPMap, [spDetails.id] : accDetails }));
                })
              })
              Object.keys(accDetails.Beneficiaries).forEach((k, i)=> {
                GetBeneficiary(k).then((spDetails) => {(
                  setBeneficiaryList((beneficiaryList)=>[...beneficiaryList, spDetails]));
                  setAccountBenMap((accountBenMap)=> ({...accountBenMap, [spDetails.id] : accDetails}));
                })
              })
 
             }).catch((error)=> {
               console.log("Error in Get Account details", error);
            });
       })
      }
      }).catch((error) => {
        console.error("Error fetching accounts:", error);
        setShowAlert(true);
        setAlertText("Error fetching data from server. Please try again!");
      });
    
    }

    useEffect(() => {
          updateList();
          setShowCreateBeneSP(false);
      }, []);
    

    return <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
    <h1 className="bold-text-input">Service Provider Profiles</h1>
    
    <CreateBeneficiarySP show={showCreateBeneSP}  showFn={showFn}/>
    <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={deleteSPBen} onCancelFn={()=>{setShowDeleteDlg(false)}} show={showDeleteDlg}/>

    <div style={{ marginTop: "20px" }}>
            {serviceProviderList.length === 0 && beneficiaryList.length === 0 ? <div className="w-full h-full flex items-start justify-start text-small">
                    <p className="text-start pb-2">Add your profile information so people can reach out to you. Click the button below to get started!</p></div>: <></>}
              <button className="button-style hover:bg-555" onClick={addServiceProvider}>
                <AiOutlineProfile className="inline-icon" />
                Add Service Provider Profile
              </button>
              <div className="p-3"></div>
              <div
              class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
              role="alert"
              style={{ display: showAlert ? "block" : "none" }}
            >
              <span class="text-sm">{alertText} </span>
            </div>
            <ul class="w-full divide-y divide-gray-700 py-3">
            {serviceProviderList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((sp, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       {displayLogoURL(sp.tags?.logourl?.Value)}

                          <div class="flex-1">
                             <p class="text-base font-semibold truncate text-blue-400">
                             <Link to={`/AddServiceProviderProfile/${sp.id}`} state={{previousPage:"/serviceProviders"}}>

                         {`${sp.tags?.name?.Value || "No Name"}`}</Link>
                             </p>
                             
                          </div>
                          
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleDeleteClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                          </button>
                          </div>
                       </div>
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                            Service Provider
                          </div>
                       <p class="text-sm text-gray-400">
                       <span id={"sp_guid_" +index }>{sp.id}</span><CopyToClipboard elId={"sp_guid_" +index }/>
                             </p>
                      <button formMethod="dialog" formTarget="top" id="templates" className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                      {spTemplates[sp.id] ? <Link to={`/serviceprovidertemplates/${sp.id}`}><CgTemplate className="inline-icon" /> {spTemplates[sp.id] + " Templates"}</Link>
                        : <Link to="/addtemplate/" state={{"spid":sp.id, previousPage:"/serviceProviders" }} ><CgTemplate className="inline-icon" /> Add Template</Link>}

                      </button>
                    </li>
                      
                  })
                }
                {beneficiaryList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((sp, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       {displayLogoURL(sp.tags?.logourl?.Value)}

                          <div class="flex-1">

                             <p class="text-base font-semibold truncate text-blue-400">
                             <Link to={`/AddBeneficiaryProfile/${sp.id}`} state={{previousPage:"/serviceProviders"}}>
                              {`${sp.tags?.name?.Value || "No Name"}`}</Link></p>   
                          </div>
                         
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteBen" onClick={() => handleDeleteClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                          </button>
                          </div>
                       </div>
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                          Beneficiary
                          </div>
                       <p class="text-sm text-gray-400">
                       <span id={"ben_guid_" +index }>{sp.id}</span><CopyToClipboard elId={"ben_guid_" +index }/>
                        </p>
                    </li>
                      
                  })
                }
                </ul>
    </div>
    </div>
}