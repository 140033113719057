import { useEffect, useState } from "react"
import { GetVisitLinkByLabel } from "../utilities/calls";
import { useParams } from "react-router-dom";

export default function VisitLink() {
    const { label } = useParams();
    const [error, setError ]= useState("");

    useEffect(() => {
        sessionStorage.setItem('redirectto', '/visitlink/' + label)
        GetVisitLinkByLabel(label).then((resp) => {
            window.location.replace(resp)
        }).catch(err => {
            console.log("err", err)
            setError("The link you are trying to visit is no longer valid.")
        })
    }, [])
    return error !== "" ?
        <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
            role="alert">
            <span class="text-sm">{error} </span>
        </div> : <></>
}
