import { useEffect, useState } from "react"
import { GetAddress, getInfo, GetSharesWith } from "../utilities/calls";
import { useParams, Link } from "react-router-dom";
import { AiOutlineProfile } from "react-icons/ai";
import CopyToClipboard from "./CopyToClipboard";
import { address_locales, countryLocaleList } from "../utilities/templates";
import { renderField } from "../utilities/functions";
import CreateCustomTag from "./CreateCustomTag";

export default function VisitLinkContent() {
    const { individualID, type, contentid } = useParams();
    const [updatedaddress, updateAddress] = useState({
        name: "",
        addressee: "",
        street: "",
        city: "",
        zipcode: "",
        state: "",
        phone: "",
        email: "",
        country: "",
    });

    const [address, setAddress] = useState({});
    const [customTags, setCustomTags] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(
        Intl.NumberFormat().resolvedOptions().locale
    );

    const handleInputChange = (e) => {
        if (e.target.name === "country") {
            handleCountryChange(e);
            return;
        }
        const { name, value } = e.target;
        updateAddress((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleCountryChange = (e) => {
        const countryVal = countryLocaleList.find(
            (o) => o.name === e.target.value
        ).value;
        updateAddress((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));

        setSelectedCountry(countryVal);
    };

    const addCustomTag = (tag) => {
        console.log(tag)
        var tagname = tag.Name;
        var tags = customTags;
        tags[tagname] = tag;
        setCustomTags((prevValues) => ({
            ...prevValues, [tagname]: tag
        }))
    }

    const removeCustomTag = (tagName) => {
        console.log(tagName)
        setCustomTags((prevValues) => { delete prevValues[tagName]; return prevValues; })
    }




    useEffect(() => {
        updateAddress({
            addressee: address.addressee,
            street: address.street,
            city: address.city,
            zipCode: address.zipCode,
            state: address.state,
            phone: address.phone,
            email: address.email,
            country: address.country,
            name: address?.tags?.atag?.Name,
        });
        var keys = Object.keys(updatedaddress).map((key) => { return key.toLowerCase() });
        let tags = {};
        console.log(address.tags)
        if (address.tags !== undefined) {
            Object.keys(address.tags).map((tag) => {
                var name = address.tags[tag].Name;
                if (keys.indexOf(name.toLowerCase()) === -1 && tag.localeCompare("atag") !== 0)
                    tags[name] = address.tags[tag];
            })
        }
        setCustomTags(tags);

    }, [address]);

    useEffect(() => {
           //console.log(resp)
            const individualid = getInfo("IndividualID");
            //Get record by address id / beneficiary id
            GetSharesWith(individualid)
                .then((data) => {
                    let el = data.find((e) => e.addressID === contentid)
                    GetAddress(contentid,individualid , el.token, individualID).then((resp) => {
                        setAddress(resp);
                        const countryVal = countryLocaleList.find((o) => o.name === resp.country).value;
                        setSelectedCountry(countryVal);

                    })

                }).catch(err => console.log(err))
            sessionStorage.removeItem('redirectto')
    }, [])
    return (
        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <div className="mx-auto max-w-screen-lg form-container p-5">
                <div>
                    <h1 className="py-2 form-label">
                        <AiOutlineProfile className="inline-icon" />
                        Address Details
                    </h1>
                    <h1 id="addrId">{address.id}</h1><CopyToClipboard elId="addrId" />
                </div>
                <fieldset disabled="disabled">
                    <div>
                        {address_locales[selectedCountry]
                            ? address_locales[selectedCountry].map((element) => {
                                return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                            })
                            : address_locales["default"].map((element) => {
                                return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                            })}
                    </div>
                    <div className="py-5"><CreateCustomTag saveFn={addCustomTag} savedTags={customTags} deleteFn={removeCustomTag} /></div>
                </fieldset>
                <section className="grid grid-cols-12 gap-20 py-2">

                    <Link to="/">
                        <button className="button-style">Close</button>
                    </Link>
                </section>
            </div>
        </div>
    );
}
