import { SearchBeneficiaries, SearchSP, getInfo, CreatePrimaryToken, CreateSecondaryToken } from "../utilities/calls";
import { useEffect, useState } from "react";
import { serviceCategoriesList } from "../utilities/templates";
import { displayEmaillink, displayPhoneLink, displayWeblink } from "../utilities/functions";
import { AiOutlinePhone, AiOutlineGlobal, AiOutlineMail, AiOutlineSearch } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";




export default function ShareContentModal  ({content, onClose, address})  {
    const [resultsSP, setResultsSP] = useState([]);
    const [resultsBen, setResultsBen] = useState([]);
    const [selectedBen, setSelectedBen] = useState([]);
    const [selectedSP, setSelectedSP] = useState([]);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [selected, setSelected] = useState([]);
   

    const toggleBenCheck = (e) => {
        if(e.target.checked) {
          setSelectedBen((selectedBen) => [...selectedBen, e.target.id])
          setSelected((selected) => [...selected , e.target.dataset.name]);
        } else {
          setSelectedBen((selectedBen) => ( [...selectedBen.filter(ben => ben !== e.target.id) ]    ))
          setSelected((selected) => ([...selected.filter(s => s !== e.target.dataset.name) ] ))
        }
    }

    const toggleSPCheck = (e) => {
        console.log(e.target.dataset.name)
        if(e.target.checked) {
          setSelectedSP((selectedSP) => [...selectedSP, e.target.id])
          setSelected((selected) => [...selected , e.target.dataset.name]);
        } else {
          setSelectedSP((selectedSP) => ( [...selectedSP.filter(ben => ben !== e.target.id) ]    ))
          setSelected((selected) => ([...selected.filter(s => s !== e.target.dataset.name) ] ))

        }
    }
    

    const updateList = (search) => {
        SearchSP(search).then((resp) => { console.log("searchSP", resp); setResultsSP(resp) }).catch((err) => { console.log("Error search sp", err) })
        SearchBeneficiaries(search).then((resp)=> { console.log("searchBeneficiaries", resp); setResultsBen(resp) }).catch((err) => { console.log("Error search ben", err) })
    }
    useEffect(() => {
        const s = "";
        updateList(s);
    }, []);

    const shareContent = () => {
        console.log(selectedBen, selectedSP);
        const def = getInfo("DefaultServiceProvider")
        const individualid = getInfo("IndividualID");
        selectedBen.forEach((benid)=> {
            if (address) {
                CreatePrimaryToken(def, address.id , individualid, "").then((resp) => {
                    CreateSecondaryToken(def, benid ,resp.token).then(()=> {
                        setShowAlert(true);
                        setAlertText("Content shared with Beneficiary");                     
                    }).catch(e=>{
                        console.error("Error contacting Beneficiary", e);
                        setAlertText("Error contacting Beneficiary, please try again!");
                        setShowAlert(true)
                    })
                    }).catch((err) => {
                    console.error("Error contacting Beneficiary", err);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })
    

            } else {

                CreatePrimaryToken(def, "", individualid, content.id).then((resp) => {
                    CreateSecondaryToken(def, benid, resp.token).then(() => {
                        setShowAlert(true);
                        setAlertText("Content shared with Beneficiary");
                    }).catch(e => {
                        console.error("Error contacting Beneficiary", e);
                        setAlertText("Error contacting Beneficiary, please try again!");
                        setShowAlert(true)
                    })
                }).catch((err) => {
                    console.error("Error contacting Beneficiary", err);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })
            }
        })

        selectedSP.forEach((spid)=> {
            if (address) {
                CreatePrimaryToken(spid, address.id, individualid, "").then(() => {
                    setShowAlert(true);
                    setAlertText("Content shared with Service Provider");
                }).catch((err) => {
                    console.error("Error contacting Service Provider", err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            } else {

                CreatePrimaryToken(spid, "", individualid, content.id).then(() => {
                    setShowAlert(true);
                    setAlertText("Content shared with Service Provider");
                }).catch((err) => {
                    console.error("Error contacting Service Provider", err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            }
        })
    }
    const search = (e) => {
        let val = document.getElementById("searchmodal");
        updateList(val.value);
    }

    const searchKey = (e) => { 
        console.log(searchKey, e)
        if(e.key === 'Enter')
        search() 
    }

   

    return <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
            <div className='w-11/12 max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>
            <div className="py-3 px-2">
            <button className="absolute top-2 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={onClose}>X</button>

                <h1 className="form-label">
                    Share {content?.tags?.name?.Value || content?.tags?.title?.Value || address?.tags?.atag?.Name} with
                </h1>
                <div class="relative w-full p-2">
        <input type="search" onKeyUp={searchKey} id="searchmodal" class="block w-full form-input" style={{"margin-bottom": "0", "padding-top":"0.5rem"}} placeholder="Search Businesses" />
        <button type="submit" onKeyUp={searchKey} onClick={search} class="text-white absolute end-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1 me-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" style={{ bottom: ".8rem" }}><AiOutlineSearch className="inline-icon" /> Search</button>
    </div>

                <div class="w-full border border-gray-700 border-x-0 max-h-48 p-4 overflow-y-auto ">
                    
                    <ul class="w-full divide-y divide-gray-700">
                        {
                             resultsSP?.map ((r, index) => {
                                return <li class="py-3 sm:py-4" >
                                    
                                    <div className=" flex items-center ps-2">
                                    <input name="spCheck" onChange={toggleSPCheck} data-name={r.tags?.name?.Value} id={r.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"/>

                                        <button class="p-1 text-sm md:font-medium font-semibold truncate">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : ""} <span class="font-normal text-gray-100 text-xs italic"> Service Provider</span></button>
                                    </div>
                                    <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                    {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value )?.name || "" }</p> : <></>}
                                    {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                        {r.tags?.description?.Value}
                                    </p> : <></>}
                                    {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                    </p> : <></>}
                                    {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                    </p> : <></>}
                                    {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.country?.Value}
                                    </p> : <></>}
                                    {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon"/>{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                    {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon"/>{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                    {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon"/>{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                </li>
                            }) 
                        }
                        {
                            resultsBen?.map((r, index) => {
                                return <li class="py-3 sm:py-4 " >
                                    
                                    <div className=" flex ps-2 items-center">
                                    <input name="benCheck" onChange={toggleBenCheck} data-name={r.tags?.name?.Value} id={r.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"/>

                                        <button class="p-1 text-sm md:font-medium font-semibold truncate">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : ""} <span class="font-normal text-gray-100 text-xs italic"> Beneficiary</span></button>
                                    </div>
                                    <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                    {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value )?.name || "" }</p> : <></>}
                                    {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                        {r.tags?.description?.Value}
                                    </p> : <></>}
                                    {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                    </p> : <></>}
                                    {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                    </p> : <></>}
                                    {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.country?.Value}
                                    </p> : <></>}
                                    {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon"/>{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                    {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon"/>{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                    {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon"/>{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                </li>
                            }) 
                        }
                    </ul>
                
            </div>
            <p className="px-2 py-1">{selected.join(", ")}</p>
            <div className="flex items-start justify-start space-x-4 p-3 "><button disabled={selectedBen.length === 0 && selectedSP.length === 0} onClick={shareContent} class="button-style positive-action">
            <FaShareAlt className="inline-icon"/>
     Share Content
  </button>
  <button onClick={onClose} className="button-style">Close</button>

            </div>
            </div>
            <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }} >
            <span class="text-sm">{alertText} </span>
          </div>
        </div>
    </div>
}