import { useEffect, useState } from "react"
import { renderField } from "../utilities/functions";
import { useNavigate } from "react-router-dom";

export default function BusinessOnboarding() {
    const navigate = useNavigate();

    const [fullAddress, setFullAddress] = useState();

    const addServiceProvider = () => {
        document.getElementsByTagName("nav")[0].style.display = "";
        let spid = "00000000-0000-0000-0000-000000000000";
        if (fullAddress) navigate('/AddBeneficiaryProfile/' + spid, { state: { previousPage: "/" } })
        else navigate('/AddServiceProviderProfile/' + spid, { state: { previousPage: "/" } })

    }
    const handleInputChange = (e) => {
        setFullAddress(e.target.checked);
    }

    const createProfile= (e) =>{
        document.getElementById('askben').style.display= ""
        e.target.closest("a").style.display = "none"
    }

    return <>
                <section class="border-gray-700 border rounded-lg">
    <div class=" px-4 py-8 mx-auto ">
        <div class="w-full mr-auto place-self-center ">
            <h1 class=" mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-white">JustWhere for Business</h1>
            <p class="mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-gray-400">Create your profile on JustWhere. Once done, people can contact and share content with you securely.</p>
            <a onClick={(e) => {e.preventDefault(); createProfile(e);}} href="#" class="bg-blue-500 inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:ring-primary-900">
                Get started
                <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            
        </div>
        <div class="my-4 w-full  p-6 space-y-8 sm:p-8  rounded-lg shadow-xl bg-gray-800" id="askben" style={{display : "none"}}>
                <h2 class="text-2xl font-bold text-white">
                    Profile
                </h2>
                <form class="mt-8 space-y-6" action="#">
                    <div class="flex items-start " >
                        <div class="flex items-center h-5">
                            <input onChange={handleInputChange} id="fulladdr" aria-describedby="fulladdr" name="fulladdr" type="checkbox" class="w-4 h-4 rounded focus:ring-3 focus:ring-blue-600 ring-offset-gray-800 bg-gray-700 border-gray-600" required />
                        </div>
                        <div class="ms-3 text-sm">
                        <label for="fulladdr" class="font-medium text-gray-400">My business requires full content/address view (You will be added as beneficiary)</label>
                        </div>
                    </div>
                    <button onClick={addServiceProvider} type="submit" class="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg focus:ring-4 sm:w-auto bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Create</button>
                    
                </form>
            </div>
                  
    </div>
</section>
    </>
}