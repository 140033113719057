// src/components/Sharing.js
import React, { useEffect, useLayoutEffect, useState } from "react";
import { GetAddress, GetShares, GetSharesWith, getInfo, DeleteSecondaryToken, DeletePrimaryToken, GetRequestDetails, CreateSecondaryToken, GetServiceProvider, GetBeneficiary, GetSecuredContent, GetSharedSecuredContent, GetVisitLabelByToken, GetTemplateByID, GetTemplatesByServiceProviderID } from "../utilities/calls";
import "./Sharing.css";
import Login from "./Login";
import DeleteModal from "./DeleteModal";
import AddressBox from "./AddressBox";
import ContentBox from "./SecuredContentBox";
import { contentTypeList } from "../utilities/templates";


const IndividualShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [addressesSharedWithBene, setAddressesSharedWithBene] = useState([]);
    const [addressesSharedWithSP, setAddressesSharedWithSP] = useState([]);

    const [shares, setShares] = useState([]);
    const [spShares, setSpShares] = useState([]);
    const [sharesWithBen, setSharesWithBen] = useState([]);
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [currShare, setCurrShare] = useState();
    const [sP, setSP] = useState();
    const [refBens, setRefBens] = useState({});
    const [rNote, setRNote] = useState({});
    const [benList, setBenList] = useState({});
    const [spList, setSpList] = useState({});
    const [contenttype, setContenttype] = useState(localStorage.getItem(document.location.hostname + ".shares.contenttype") || "" );
    const [securedContents, setSecuredContents] = useState([]);
    const [visitLink, setVisitLink] = useState({});
    const [templates, setTemplates] = useState([{Name: "address_locales", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
}}}]);



    useEffect(() => {
        setUserId(getInfo("UserID"));
        setShowDeleteDlg(false);
        let defaultSP = getInfo('DefaultServiceProvider');
        getTemplates(defaultSP);
    }, []);

    const getTemplates = (spid) => {
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((template) => setTemplates((templates) =>   [...templates, template])).catch(err => console.log('Error fetching templates for spid ', spid, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }

    const saveContenttype = (val) => {
        localStorage.setItem(document.location.hostname + ".shares.contenttype", val);
        setContenttype(val)
    }

    useEffect(() => {
        const individualid = getInfo("IndividualID");

        GetSharesWith(individualid)
            .then((data) => {
                setShares(data);
                Promise.all(
                    data.filter(d => d.securedcontentID === "" ).map(async (el) => {
                        try {
                            const benId = el.tags?.RequestId ? "" : el.beneficiaryID
                            const result = await GetAddress(
                                el.addressID,
                                benId,
                                el.token,
                                el.individualID
                            );
                            
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddresses((addresses) => [...addresses, ...addrs]);
                    })
                    .catch((e) => {
                        console.log(e);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    });
                    Promise.all(
                        data.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            const benId = el.tags?.RequestId ? "" : el.beneficiaryID

                            try { return await GetSharedSecuredContent(el.securedcontentID, el.token, benId ,el.individualID); 
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
            })
            .catch((error) => {
                console.error("Error fetching sharedWith:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
        getSharesWithBene();    
    }, []);

    const getSharesWithBene = () => {
        const individualid = getInfo("IndividualID");
        const ownertoken = getInfo("Ownertoken")
        GetShares(individualid)
            .then((data) => {
                
                let ben = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                setSharesWithBen(ben);
                
                ben.map((b) => {
                        if(b.serviceProviderID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0)
                            GetBeneficiary(b.beneficiaryID).then((el) => setBenList((benList) => ({...benList, [b.beneficiaryID] : el}))).catch((err) => console.log(err))

                    })

                Promise.all(
                    ben.filter(d => d.securedcontentID === "" ).map(async (el) => {
                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithBene((addressesSharedWithBene) => [
                            ...addressesSharedWithBene,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                    Promise.all(
                        ben.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            try { return await GetSecuredContent(el.securedcontentID);
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
                
                    
                let sp = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") === 0);
                setSpShares(spShares => [...spShares, ...sp]);

                Promise.all(
                    sp.filter(d => d.securedcontentID === "" ).map(async (el) => {

                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithSP((addressesSharedWithSP) => [
                            ...addressesSharedWithSP,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                    Promise.all(
                        sp.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            try { return await GetSecuredContent(el.securedcontentID);
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
                sp.map((s) => {
                    try {
                        GetTemplatesByServiceProviderID(s.serviceProviderID);
                       
                        GetServiceProvider(s.serviceProviderID).then((el) => setSpList((spList) => ({...spList, [s.serviceProviderID] : el}))).catch((err) => console.log(err))


                        return GetRequestDetails(s.tags?.RequestId?.Value).then((r) => {
                            const refs = r.tags?.beneficiaryReference?.Value || [];
                            setRNote((rNote) => ({...rNote, [s?.tags?.RequestId?.Value] : r.tags?.note?.Value || ""}))
                            setRefBens((refBens) => ({ ...refBens, [s?.tags?.RequestId?.Value]: refs }));
                        }).catch((err) =>{
                            console.log(err);
                        });

                    } catch (err) {
                        console.error("Error fetching request details:", err);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    }
                })
            })
            .catch((error) => {
                console.error("Error fetching shares:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
    }

    useEffect(()=> {
        let temp = [... new Set(securedContents.map(sc=>sc?.tags?.contenttype?.Value))];
        temp.forEach((name) => {
           if(templates.findIndex((t) => t.Name === name) === -1){
                setTemplates((templates) => [...templates, {Name: name}])
            }
        })
    },[securedContents])

    const deleteServiceProviderShare = () => {
        setShowDeleteDlg(false);
        console.log('deleteSPShare')

        const shareReq = currShare

        DeletePrimaryToken(shareReq.serviceProviderID, shareReq.individualID, shareReq.addressID, shareReq.securedcontentID)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithSP([])
                setAddressesSharedWithBene([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
            });
    }

    const deleteShare = () => {
        setShowDeleteDlg(false);
        if (sP) { deleteServiceProviderShare(); return; }
        const shareReq = currShare;
        var addressOffered = !shareReq.tags?.RequestId 
        let id = shareReq.serviceProviderID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? (addressOffered ?  shareReq.tags.SharedWithEmail.Value : "")  : shareReq.beneficiaryID;
        
        DeleteSecondaryToken("", id, shareReq.token)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithBene([])
                setAddressesSharedWithSP([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
                
            });
    }
    const handleDeleteClick = (share, sp) => {
        console.log(addressesSharedWithBene, shares, sp)
        setConfirmationText("Are you certain you wish to Remove the sharing of this address?")
        setShowDeleteDlg(true);
        setCurrShare(share);
        setSP(sp)
    }

    const handleGetVisitLink = (share, e) => {
        if(visitLink[share.addressID] && visitLink[share.addressID] !== "") {
            navigator.clipboard.writeText(visitLink[share.addressID] ); 
            return;
        }
        GetVisitLabelByToken(share.token).then((resp) => {
            let visitlink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp;
            setVisitLink(visitLink => ({...visitLink, [share.addressID] : visitlink}) );
            navigator.clipboard.writeText(visitlink);    
            const button = e.target.closest("button");
            button.classList.add('text-green-400');
            button.insertAdjacentHTML('afterend',"<span class='text-sm text-green-400'> Copied to clipboard!</span>")
          }
        ).catch((err) => console.log('err',err));
    }


    const connect = (sp, address, content) => {
        if(address === "") {
            const shareReq = spShares.find((el) => el.securedcontentID === content.id && sp.serviceProviderID === el.serviceProviderID);
            CreateSecondaryToken(shareReq.serviceProviderID, refBens[sp?.tags?.RequestId?.Value][0] , shareReq.token).then((resp) => { 
                setShowAlert(true);
                setAlertText("You have connected with the recommended beneficiary! ");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithBene([])
                setAddressesSharedWithSP([])
                setSpShares([])
                getSharesWithBene();
            }).catch((err) => {console.log('error creating secondary token', err)})
        } else {
        console.log('connect ', sp , address);
        const shareReq = spShares.find((el) => el.addressID === address.id && sp.serviceProviderID === el.serviceProviderID);
        CreateSecondaryToken(shareReq.serviceProviderID, refBens[sp?.tags?.RequestId?.Value][0] , shareReq.token).then((resp) => { 
            setShowAlert(true);
            setAlertText("You have connected with the recommended beneficiary! ");
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            setAddressesSharedWithBene([])
            setAddressesSharedWithSP([])
            setSpShares([])
            getSharesWithBene();
        }).catch((err) => {console.log('error creating secondary token', err)})
    }
    }
    
    return userId ? (

        <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
            <h1 className="bold-text-input">Secure Content Shares</h1>
            { contenttype === "" ? <p className="text-small py-3">Choose content type to view secured content shared by or shared with you</p> : <></>} 
            <DeleteModal confirmationText={confirmationText} deleteLabel="Remove Share" onDeleteFn={deleteShare} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
            <div class="block w-auto " id="navbar-default">
                <ul class="font-medium flex mt-4 flex-row space-x-2 md:space-x-8 border-y-2 border-gray-700 ">
                    {templates.map(element => {
                        return <li>  <a onClick={(e) => { e.preventDefault(); saveContenttype(element.Name); return false; }} id={"tab_" + element.Name} href="#" class={contenttype === element.Name ? "block py-2 px-3 rounded bg-transparent  p-0 text-blue-500" : "block py-2 px-3 rounded bg-transparent border-0 p-0 text-white hover:text-blue-500 "} >{element?.Tags?.DisplayName?.Value || element.Name}</a>
                        </li>
                    })}
                </ul>
            </div> 
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>

            { contenttype === "" ? <></> : contenttype === "address_locales" ? <>        
            
            <div>
                <h1 className="p-5 bold-text-input">Addresses Shared with me</h1>
                <div class=" bg-gray  ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                shares && shares!== undefined && shares.length > 0 ?
                                shares.map((share) => {
                                    var address = addresses.find((ele) => share.addressID === ele?.id)
                                    var from = decodeURIComponent(
                                        share?.tags?.UserEmail ? share.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : ""
                                    );
                                    var addressOffered = !share.tags?.RequestId 
                                    return <AddressBox created={share?.created ? share.created : ""} updated={share?.updated ? share.updated : ""} address={address} from={from} to={to} sentBadge={addressOffered} />

                                }) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Service Provider</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                spShares.map((sp) => {
                                    var address = addressesSharedWithSP.find((ele) => sp.addressID === ele?.id)
                                    var bn = refBens[sp?.tags?.RequestId?.Value] || [];
                                    var connected = sharesWithBen.find((el)=> el.serviceProviderID === sp.serviceProviderID && el.addressID === sp.addressID)
                                    var serviceProviderID=sp.serviceProviderID
                                    var from = decodeURIComponent(
                                        sp?.tags?.UserEmail ? sp.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        sp?.tags?.SharedWithEmail ? sp.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <AddressBox created={sp?.created ? sp.created : ""} updated={sp?.updated ? sp.updated : ""}  from={from} to={to} benList={benList} connected={connected} sharedTo={spList[sp.serviceProviderID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(sp, true)} ben={bn} message={rNote[sp?.tags?.RequestId?.Value]} onConnect={() => connect(sp, address)} serviceProviderId={serviceProviderID}></AddressBox>
                                        
                                       
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Beneficiary</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3 gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                sharesWithBen.map((ben) => {
                                    var address = addressesSharedWithBene.find((ele) => ben.addressID === ele?.id)
                                    var benId=ben.beneficiaryID
                                    var from = decodeURIComponent(
                                        ben?.tags?.UserEmail ? ben.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        ben?.tags?.SharedWithEmail ? ben.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                    { from !== "" ?
                                        <AddressBox created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(ben, false)} benId={benId} onGetVisitLink={(e) => handleGetVisitLink(ben, e)}></AddressBox>
                                        :
                                        <AddressBox created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(ben, false)} benId={benId} ></AddressBox>
                                    }
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            </> : <>
            
            <div>
                <h1 className="p-5 bold-text-input">{contentTypeList.map((ele) => { if(contenttype === ele.value) return ele.name} )} Shared with me</h1>
                <div class=" bg-gray  ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                shares && shares!== undefined && shares.length > 0 ?
                                shares.map((share) => {
                                    var content = securedContents.find((ele) => share.securedcontentID === ele?.id)

                                    if(content && content.tags?.contenttype?.Value === contenttype) {
                                        var from = decodeURIComponent(
                                            share?.tags?.UserEmail ? share.tags.UserEmail.Value : ""
                                        )
                                        var to = decodeURIComponent(
                                            share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : ""
                                        );
                                        return <ContentBox contenttype={contenttype}  created={share?.created ? share.created : ""} updated={share?.updated ? share.updated : ""} content={content} from={from} to={to} />
                                    } else return <></>
                                }) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Service Provider</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                spShares.map((sp) => {
                                    var content = securedContents.find((ele) => sp.securedcontentID === ele?.id)
                                    if(content && content.tags?.contenttype?.Value === contenttype) {

                                    var bn = refBens[sp?.tags?.RequestId?.Value] || [];
                                    var connected = sharesWithBen.find((el)=> el.serviceProviderID === sp.serviceProviderID && el.securedcontentID === sp.securedcontentID)
                                    var serviceProviderID=sp.serviceProviderID
                                    var from = decodeURIComponent(
                                        sp?.tags?.UserEmail ? sp.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        sp?.tags?.SharedWithEmail ? sp.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <ContentBox contenttype={contenttype} created={sp?.created ? sp.created : ""} updated={sp?.updated ? sp.updated : ""}  from={from} to={to} benList={benList} connected={connected} sharedTo={spList[sp.serviceProviderID]?.tags?.name?.Value} content={content} onDelete={() => handleDeleteClick(sp, true)} ben={bn} message={rNote[sp?.tags?.RequestId?.Value]} onConnect={() => connect(sp, "", content)} serviceProviderId={serviceProviderID}/>
                                        
                                       
                                    </>
                                    } else return <></>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Beneficiary</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3 gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                sharesWithBen.map((ben) => {
                                    var content = securedContents.find((ele) => ben.securedcontentID === ele?.id)
                                    if(content && content.tags?.contenttype?.Value === contenttype) {

                                    var benId=ben.beneficiaryID
                                    var from = decodeURIComponent(
                                        ben?.tags?.UserEmail ? ben.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        ben?.tags?.SharedWithEmail ? ben.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <ContentBox contenttype={contenttype} created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} content={content} onDelete={() => handleDeleteClick(ben, false)} benId={benId} />
                                        
                                       
                                    </>
                                    } else return <></>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            </>
        }
        </div>

    ) : (
        <Login />
    );
};

export default IndividualShares;
