import { BsFillTrash3Fill } from "react-icons/bs";
import CopyToClipboard from './CopyToClipboard';
import DeleteModal from './DeleteModal';
import React, { useEffect, useState } from 'react';
import { getInfo, GetSecuredContents, DeleteSecureContent, GetSharesForSecureContent, GetTemplatesByServiceProviderID, GetTemplateByID } from "../utilities/calls";
import {  AiOutlineProfile } from "react-icons/ai";
import AddressList from "./AddressList";
import { Link, useLocation } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import ShareContentModal from "./ShareContentModal";
import { handleAccordionClick, displayDate } from "../utilities/functions";

export default function SecureContentList() {
    const [securedcontents, setSecuredcontents] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currSpId, setCurrSpId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState();
    const [addrCount, setAddrCount] = useState();
    let {state} = useLocation();
    const [defaultTemplates, setDefaultTemplates] = useState([{Name: "address_locales", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
}}}]);
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        loadList();
        let defaultSP = getInfo('DefaultServiceProvider');
        if(defaultSP)
            getTemplates(defaultSP, true);
    }, []);

    
    const getTemplates = (spid, def) => {
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                if(def) {
                    GetTemplateByID(id).then((template) => setDefaultTemplates((templates) =>   [...templates, template])).catch(err => console.log('Error fetching templates for spid ', spid, err));
                } else {
                    GetTemplateByID(id).then((template) => setTemplates((templates) =>   [...templates, template])).catch(err => console.log('Error fetching templates for spid ', spid, err));
                }
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }

    const loadList = () => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        GetSecuredContents()
            .then((resp) => {
                setSecuredcontents(resp);
                const fetchCounts = async (contents) => {
                    const sharesPromises = contents.map((c) =>
                      GetSharesForSecureContent(c.id)
                        .then((response) => {
                            response.forEach(r => getTemplates(r.serviceProviderID));
                          return { securedcontentid: c.id, count: response.length };
                        })
                        .catch((error) => {
                          console.error(`Error shares for ID ${c.id}:`, error);
                          setShowAlert(true);
                          setAlertText("Error fetching shares");
                        })
                    );
                    Promise.all(sharesPromises)
                      .then((details) => {
                        const cmap = {};
                        details.forEach(
                          (detail) => (cmap[detail.securedcontentid] = detail.count)
                        );
                        setSharedCounts(cmap);
                      })
                      .catch((error) => {
                        console.error("Error setting record details:", error);
                        setShowAlert(true);
                        setAlertText("Error setting record details");
                      });
                  };
                  if (resp && resp.length > 0) {
                    fetchCounts(resp);
                  }
            })
            .catch((error) => {
                console.error("Error in getSecureContent:", error);
                setShowAlert(true);
                setAlertText('Error loading data, please try again')
            });
    }

    
    const handleDeleteClick = (spid) => {
        setShowDeleteDlg(true);
        setCurrSpId(spid);
    }

    const deleteSC = () => {
        setShowDeleteDlg(false);

        DeleteSecureContent(currSpId, true).then(() => {
            setShowAlert(true);
            setAlertText("Secured Content was successfully deleted.");
            setTemplates([])
            loadList();
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        });
    }

    const handleShareClick= (sc) => {
        setSelectedContent(sc);
        setShowShareModal(true);
    }

    const renderRow = (sc, contenttype, scount, index, template) => {
        return (<li class="py-3 sm:py-2">

            <div class="flex items-center space-x-4 rtl:space-x-reverse">

                <div class="flex-1">
                    <Link class="font-semibold truncate" to={`/addsecurecontent/${sc.id}`} state={{"contenttype": contenttype, "template": template}}>

                        {`${sc.tags?.name?.Value || sc.tags?.Name?.Value || sc.tags?.title?.Value || "No Name"}`}
                    </Link>
                    <span class="text-sm font-medium mx-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400"> {`${scount}`} Shares </span>

                </div>

                <div class="inline-flex items-center text-white">
                    <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleDeleteClick(sc.id)} className="bg-slate-333 py-1 hover:bg-555">
                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                    </button>
                </div>
            </div>
            <p className="mt-1 text-xs text-gray-400">{sc.updated ? "Updated " + displayDate(sc.updated) : ""}  </p>

            {sc.updated && sc.created && sc.updated !== sc.created ? <p className="text-xs text-gray-400">{sc.created ? "Created " + displayDate(sc.created) : ""}</p> : <></>}

            <p class="text-sm text-gray-200 mt-1">
                <span id={"sc_guid_" + index}>{sc.id}</span><CopyToClipboard elId={"sc_guid_" + index} />
            </p>
            <button formMethod="dialog" formTarget="top" id="sharecontent" onClick={() => handleShareClick(sc)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                <FaShareAlt className="inline-icon" />Share
            </button>
        </li>
        )
    }

    const renderSecuredContentList = (contenttype, name, template) => {
        if (securedcontents?.length === 0)
            return <p>No {name} found.</p>
        return  (securedcontents.filter(a => a.tags?.contenttype?.Value === contenttype).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sp, index) => {
            const scount = sharedCounts[sp.id]

            return renderRow(sp, contenttype, scount, index, template)

        })) 
    }

    const getOtherContents = () => {
        console.log(templates, securedcontents)
        
        let filtered = securedcontents.filter((a) => {return templates.findIndex(t => t.Name === a.tags?.contenttype?.Value) === -1 &&  defaultTemplates.findIndex(t => t.Name === a.tags?.contenttype?.Value) === -1 });
        const l = Object.groupBy(filtered, (sc => sc.tags?.contenttype?.Value))    
        console.log(l) 
        return l;
    }

    return (

        <div className='space-y-3 px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center'>
            {showAlert ? (
                <div
                    class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                >
                    <span class="text-sm">{alertText} </span>
                </div>
            ) : (
                <></>
            )}
            <DeleteModal confirmationText="Are you certain you wish to delete this secured content?" deleteLabel="Delete" onDeleteFn={deleteSC} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

            <h1 className="bold-text-input">Secure Content List</h1>
            <p>{templates.length + defaultTemplates.length + Object.keys(getOtherContents())} content types found.</p>
             <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
                <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
                {defaultTemplates.sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a.Name).toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b.Name).toLowerCase()) }).map(element => {
                    return <>
                    <h2 id={"heading_" + element.Name}> 
                    <button type="button" class="flex items-center justify-between w-full py-5 font-medium rtl:text-right border-b border-gray-700 text-blue-400 gap-3" data-accordion-target={"body_"+ element.Name} aria-expanded="true" aria-controls={"body_"+ element.Name} onClick={handleAccordionClick}>
                        <span>{element?.Tags?.DisplayName?.Value || element.Name} {element.Name === "address_locales" ? addrCount : Object.keys(securedcontents.filter(a => a.tags?.contenttype?.Value === element.Name) || {}).length } <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-gray-700 text-yellow-300 border border-yellow-300">Default template</span>
 </span>
                    <svg data-accordion-icon class="w-3 h-3  shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                    </button>
                    </h2>
                    <div id={"body_" + element.Name} class="" aria-labelledby={"heading_" + element.Name}>
                    <div class="py-5 border-b border-gray-700">
                    { element.Name === "address_locales" ? <AddressList contenttype={element.Name} addressCount={setAddrCount} show={true}/>  :  
                                                  <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
                                                  <div style={{ marginTop: "20px" }}>
                                              <Link className="button-style hover:bg-555 mb-2" to='/addsecurecontent/' state={{"contenttype" : element.Name,  "template": element}}>
                                      <AiOutlineProfile className='inline-icon' />
                                      Add {element?.Tags?.DisplayName?.Value || element.Name}
                                  </Link><ul class="w-full divide-y divide-gray-700 py-3" style={{ marginTop: "20px" }}> {renderSecuredContentList(element.Name , element?.Tags?.DisplayName?.Value || element.Name, element)} </ul></div></div>}
                    </div>
                    </div>
                    </>
                })}    
                {templates.sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a.Name).toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b.Name).toLowerCase()) }).map(element => {
                    return <>
                    <h2 id={"heading_" + element.Name}>
                    <button type="button" class="flex items-center justify-between w-full py-5 font-medium rtl:text-right border-b border-gray-700 text-blue-400 gap-3" data-accordion-target={"body_"+ element.Name} aria-expanded="true" aria-controls={"body_"+ element.Name} onClick={handleAccordionClick}>
                        <span>{element?.Tags?.DisplayName?.Value || element.Name} {element.Name === "address_locales" ? addrCount : Object.keys(securedcontents.filter(a => a.tags?.contenttype?.Value === element.Name) || {}).length}</span>
                    <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                    </button>
                    </h2>
                    <div id={"body_" + element.Name} class="hidden" aria-labelledby={"heading_" + element.Name}>
                    <div class="py-5 border-b border-gray-700">
                    { element.Name === "address_locales" ? <AddressList contenttype={element.Name} addressCount={setAddrCount} show={true}/>  :  
                                                  <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
                                                  <div style={{ marginTop: "20px" }}>
                                              <Link className="button-style hover:bg-555 mb-2" to='/addsecurecontent/' state={{"contenttype" : element.Name, "template": element}}>
                                      <AiOutlineProfile className='inline-icon' />
                                      Add {element?.Tags?.DisplayName?.Value || element.Name}
                                  </Link><ul class="w-full divide-y divide-gray-700 py-3" style={{ marginTop: "20px" }}> {renderSecuredContentList(element.Name , element?.Tags?.DisplayName?.Value || element.Name, element)} </ul></div></div>}
                    </div>
                    </div>
                    </>
                })}   
                {   Object.keys(getOtherContents()).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }).map(element => {
                    var contents = getOtherContents()[element];
                    return <>
                    <h2 id={"heading_" + element}>
                    <button type="button" class="flex items-center justify-between w-full py-5 font-medium rtl:text-right border-b border-gray-700 text-blue-400 gap-3" data-accordion-target={"body_"+ element} aria-expanded="true" aria-controls={"body_"+ element} onClick={handleAccordionClick}>
                        <span>{element} {contents.length}</span>
                    <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                    </button>
                    </h2>
                    <div id={"body_" + element} class="hidden" aria-labelledby={"heading_" + element}>
                    <div class="py-5 border-b border-gray-700">
                    {   
                                                  <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
                                                  <div style={{ marginTop: "20px" }}>
                                              <Link className="button-style hover:bg-555 mb-2" to='/addsecurecontent/' state={{"contenttype" : element, template: element}}>
                                      <AiOutlineProfile className='inline-icon' />
                                      Add {element}
                                  </Link><ul class="w-full divide-y divide-gray-700 py-3" style={{ marginTop: "20px" }}> {renderSecuredContentList(element , element, element)} </ul></div></div>}
                    </div>
                    </div>
                    </>
                })}   
                
                </div>
            </div>
            {showShareModal &&    <ShareContentModal content={selectedContent} onClose={() => {setShowShareModal(false);}}/>} 


        </div>
    )

}