import { AiOutlineLogin } from "react-icons/ai";
import { RiCommunityFill } from "react-icons/ri";
import { MdDisabledVisible } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import React, { useState } from "react";
import AboutUs from "./AboutUs";
import Modal from "./Modal"
import Footer from "./Footer";


export default function Login() {
  let location = useLocation();
  const [alertText, setAlertText] = useState();
  const [showModal, setShowModal] = useState(false)

  const onlogin = () => {
    const link = "/api/login";
    window.location.replace(link);
  };

  React.useEffect(() => {
    setAlertText(location?.state?.error)
  }, [location]);
  return (
    <section>
      <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: alertText ? "block" : "none" }}>
        <span class="text-sm">{alertText} </span>
      </div>
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
        <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl text-white">Secure, Share and track easily</h1>
        <p class="mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48 text-gray-400">JustWhere is a privacy respecting service that empowers you to securely and easily share your contents (like addresses) with businesses, family and friends
          within the JustWhere community!</p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">


          <p className="py-3 flex justify-center">

            <button className="button-style" id="login" onClick={() => setShowModal(true)}>

              <AiOutlineLogin className="inline-icon" />

              Login

            </button>
          </p>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-8 px-4">
        <div class="border border-gray-700 rounded-lg p-8 md:p-12">
          <RiCommunityFill size="2rem" />
          <h2 class="text-white text-3xl font-extrabold mb-2">You are in control</h2>
          <p class="text-lg font-normal text-gray-400 mb-4">Choose precisely who sees your content and for how long. When details change, only those you have shared with get
            the latest one automatically.</p>
        </div>
        <div class="border border-gray-700 rounded-lg p-8 md:p-12">
          <MdDisabledVisible size="2rem" />
          <h2 class="text-white text-3xl font-extrabold mb-2">Safe & private storage</h2>
          <p class="text-lg font-normal text-gray-400 mb-4">Rest assured your addresses are stored securely with top notch encryption and visible only to people you choose on JustWhere community.</p>
          <a className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" href="#/security-report" target="_blank">View Security Reports</a>
        </div>
      </div>

      <div class="py-8 px-4 mx-auto max-w-screen-xl text-left lg:py-16"><AboutUs /></div>
      <Footer />

      {
        showModal && <Modal onClose={() => setShowModal(false)} login={onlogin} />
      }
    </section>
  )
};


