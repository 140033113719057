import { useEffect, useState } from "react";
import { GetSentRequestsFrom, GetReceivedRequestsFor, getInfo, FetchIndividual, GetRequestDetails, ConvertUnixTimestampToDateString } from "../utilities/calls";
import { Link } from "react-router-dom";
import { AiOutlineShareAlt, AiOutlineProfile } from "react-icons/ai";
import * as dayjs from "dayjs";



export default function RecentActivity() {
  const [individual, setIndividual] = useState({});
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [shareAccepted, setShareAccepted] = useState([]);
  const [shareRequested, setShareRequested] = useState([]);
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();



  useEffect(() => {
    const individualid = getInfo("IndividualID");
    if (individualid == null) return;
    FetchIndividual(individualid)
      .then((individualData) => {
        setIndividual(individualData);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual");
      });

    GetSentRequestsFrom(individualid)
      .then((response) => {
        setSentRequests(response);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual, please try again!");
      });
    GetReceivedRequestsFor(individualid)
      .then((response) => {
        setReceivedRequests(response);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual, please try again!");
      });
  }, [])

  useEffect(() => {
    const fetchRecordDetails = async () => {
      const detailsPromises = sentRequests.map((id) =>
        GetRequestDetails(id)
          .then((response) => response)
          .catch((error) => {
            console.error(`Error fetching details for ID ${id}:`, error);
            setShowAlert(true);
            setAlertText("Error Error fetching details");
          })
      );

      Promise.all(detailsPromises)
        .then((details) => { const shareAcceptedAddresses = details.filter((o) => o.status === "ACCEPTED"); shareAcceptedAddresses.forEach((o) => { o.type = "shareAccepted" }); setShareAccepted(shareAcceptedAddresses) })
        .catch((error) => {
          console.error("Error setting record details:", error);
          setShowAlert(true);
          setAlertText("Error setting record details");
        });
    };
    if (sentRequests?.length > 0) {
      fetchRecordDetails();
    }
  }, [sentRequests]);

  useEffect(() => {
    const fetchRecordDetails = async () => {
      const detailsPromises = receivedRequests.map((id) =>
        GetRequestDetails(id)
          .then((response) => response)
          .catch((error) => {
            console.error(`Error fetching details for ID ${id}:`, error);
            setShowAlert(true);
            setAlertText("Error fetching details");
          })
      );

      Promise.all(detailsPromises)
        .then((details) => { const newShareRequests = details.filter((o) => o.status === "NEW"); newShareRequests.forEach((o) => { o.type = "shareRequested" }); setShareRequested(newShareRequests) })
        .catch((error) => {
          console.error("Error setting record details:", error);
          setShowAlert(true);
          setAlertText("Error setting record details");
        });
    };
    if (receivedRequests?.length > 0) {
      fetchRecordDetails();
    }
  }, [receivedRequests]);

  return individual && individual.id ?
    <div className="p-4 w-full grid grid-cols-1">
      <h1 className="py-3 bold-text-input">Activity</h1>
      <div>{showAlert ? (<div class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
        <span class="text-sm">{alertText} </span>
      </div>) : <></>}</div>
      <ol class="relative border-s border-gray-900">
        {displayActivityFeed(shareRequested, shareAccepted, individual)}
      </ol>
    </div>
    : <div></div>
}

function displayActivityFeedForShareAccepted(request) {
  if(request.requestType === "SHARE_ADDRESS" ) {
  return <Link className="w-full flex flex-row hover:bg-555" to={`/viewrequestaddress-details/${request.id}`} state={{ previousPage: "/" }}>
    <li class="w-full">
      <div class="items-center justify-between p-4  border-t shadow-sm sm:flex border-gray-600">
        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 ring-1 ring-white">
          <AiOutlineProfile title="Show Content"/>    </span>
        <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(request.createdAt)}>{dayjs().to(ConvertUnixTimestampToDateString(request.createdAt))}</time>
        <div class="hover:underline p-1 text-sm font-semibold text-gray-300">{request.toIndividualEmail ? request.toIndividualEmail : "Someone"} <span class="text-sm font-normal me-2"> has shared address with you.</span></div>
        {request.tags?.note?.Value && request.tags?.note?.Value.length > 0 ? <blockquote class="text-xs italic font-semibold text-white">
          <p>{'"' + request.tags?.note?.Value + '"'}</p>
        </blockquote> : <></>}
      </div></li></Link>
  } else {
    return <Link className="w-full flex flex-row hover:bg-555" to={`/viewsecurecontent/${request.id}`} state={{ previousPage: "/" }}>
    <li class="w-full">
      <div class="items-center justify-between p-4  border-t shadow-sm sm:flex border-gray-600">
        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 ring-1 ring-white">
          <AiOutlineProfile title="Show Content" />    </span>
        <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(request.createdAt)}>{dayjs().to(ConvertUnixTimestampToDateString(request.createdAt))}</time>
        <div class="hover:underline p-1 text-sm font-semibold text-gray-300">{request.toIndividualEmail ? request.toIndividualEmail : "Someone"} <span class="text-sm font-normal me-2"> has shared content with you.</span></div>
        {request.tags?.note?.Value && request.tags?.note?.Value.length > 0 ? <blockquote class="text-xs italic font-semibold text-white">
          <p>{'"' + request.tags?.note?.Value + '"'}</p>
        </blockquote> : <></>}
      </div></li></Link>
  }
}

function displayActivityFeedForShareRequested(request, individual) {
  if(request.fromIndividualEmail) {
  return <Link className="w-full flex flex-row hover:bg-555" to={{ pathname: "/process-request" }} state={{ request: request, addresses: individual.addresses, previousPage: "/", requestFrom : request.fromIndividualEmail ? request.fromIndividualEmail : "Someone" }}><li class="w-full ">
    <div class="items-center justify-between p-4 border-t shadow-sm sm:flex border-gray-600"><span class="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 ring-1 ring-white">
      <AiOutlineShareAlt title="Share Address" />    </span>
      <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(request.createdAt)}>{dayjs().to(ConvertUnixTimestampToDateString(request.createdAt))}</time>
      <div class="hover:underline p-1 text-sm font-semibold text-gray-300">{request.fromIndividualEmail ? request.fromIndividualEmail : "Someone"} <span class="text-sm font-normal me-2"> has requested Address from you.</span></div>
      {request.tags?.note?.Value && request.tags?.note?.Value.length > 0 ? <blockquote class="text-xs italic font-semibold text-white">
        <p>{'"' + request.tags?.note?.Value + '"'}</p>
      </blockquote> : <></>}
    </div>
  </li></Link>
  } else {
    <></>
  }
}

function displayActivityFeed(shareRequested, shareAccepted, individual) {
  const activityArr = [...shareRequested, ...shareAccepted];
  activityArr.sort((a, b) => b.createdAt - a.createdAt);
  return activityArr.map((request) => { return request.type === 'shareRequested' ? displayActivityFeedForShareRequested(request, individual) : displayActivityFeedForShareAccepted(request) })

}
